//import logo from './logo.svg';
import './App.css';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './Components/Footer';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import About from './Components/About';
import Contact from './Components/Contact';
import { useEffect } from "react"

function App() {

    useEffect(() => {
    // Array of script sources
    const scriptSources = [
        "assets/js/vendor/jquery-3.6.0.min.js",
        "assets/js/vendor/modernizr-3.6.0.min.js",
        "assets/js/vendor/jquery-migrate-3.3.0.min.js",
        "assets/js/vendor/bootstrap.bundle.min.js",
        "assets/js/plugins/slick.js",
        "assets/js/plugins/jquery.syotimer.min.js",
        "assets/js/plugins/waypoints.js",
        "assets/js/plugins/wow.js",
        "assets/js/plugins/perfect-scrollbar.js",
        "assets/js/plugins/magnific-popup.js",
        "assets/js/plugins/select2.min.js",
        "assets/js/plugins/counterup.js",
        "assets/js/plugins/jquery.countdown.min.js",
        "assets/js/plugins/images-loaded.js",
        "assets/js/plugins/isotope.js",
        "assets/js/plugins/scrollup.js",
        "assets/js/plugins/jquery.vticker-min.js",
        "assets/js/plugins/jquery.theia.sticky.js",
        "assets/js/plugins/jquery.elevatezoom.js",
        "assets/js/main.js",
        "assets/js/shop.js"
    ];

    // Function to create and append a script
    const appendScript = (src) => {
        return new Promise((resolve, reject) => {
            const script = document.createElement("script");
            script.src = src;
            script.async = true;
            script.crossOrigin = "anonymous";

            script.onload = () => {
                console.log(`Script loaded successfully: ${src}`);
                resolve(script);
            };
      
            script.onerror = () => {
                console.error(`Failed to load script: ${src}`);
                reject(new Error(`Failed to load script: ${src}`));
            };

             // Append the script to the body
            document.body.appendChild(script);

            // Cleanup the script when the component unmounts
            return () => {
            document.body.removeChild(script);
            };
        });
    };

    // Append scripts in sequence to ensure order
    const loadScripts = async () => {
        try {
            for (const src of scriptSources) {
                await appendScript(src);  // Load each script sequentially
            }
        } catch (error) {
            console.error("Error loading scripts:", error);
            }
    };

    loadScripts();

    },[]);

    return (
        <>  
            <Router>
                <Navbar/>

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            
                <Footer/>
            </Router>
        </>
    );
}
export default App;
