/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
//import Slider from 'react-slick';


function Home() {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 300,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//   };

  return (
    <>
        <main className="main">
            <section className="home-slider position-relative mb-30">
                <div className="container">
                    <div className="home-slide-cover mt-30">
                        <div className="hero-slider-1 style-4 dot-style-1 dot-style-1-position-1">
                            <div className="single-hero-slider single-animation-wrap" style={{backgroundImage: "url(assets/imgs/slider/slider-1.png)"}}>
                                <div className="slider-content">
                                    <h1 className="display-2 mb-40">
                                        B2B ecommerce <br />
                                        platform for global trade
                                    </h1>
                                    {/* <p className="mb-65">Sign up for the daily newsletter</p> */}
                                </div>
                            </div>
                            <div className="single-hero-slider single-animation-wrap" style={{backgroundImage:"url(assets/imgs/slider/slider-2.png)"}}>
                                <div className="slider-content">
                                    <h1 className="display-2 mb-40">
                                        My Price My Bid<br />
                                    </h1>
                                    <p className="mb-65">Take control of your pricing and bids.<br/><br/>Learn how to strategically set your prices and win bids globally.</p>
                                    <form className="form-subcriber d-flex">
                                        <input type="email" placeholder="Your emaill address" />
                                        <button className="btn" type="submit">Subscribe</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="slider-arrow hero-slider-1-arrow"></div>
                    </div>
                </div>
            </section>

            <section className="banners mb-25 mt-40">
                <div className="container">
                    <div className="row">
                        <div className="section-title">
                            <div className="title">
                                <h3>Solutions for your Business</h3>
                            </div>
                            <div className="slider-arrow slider-arrow-2 flex-right carausel-10-columns-arrow" id="carausel-10-columns-arrows"></div>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-15">
                            <a href="#">
                                <div className="banner-img wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                    <img src="assets/imgs/banner/banner-1.png" alt="" />
                                    <div className="banner-text">
                                        <h4>
                                            Marketplace
                                        </h4>
                                        <p>
                                            Discover new markets and expand your reach with expert guidance. Dive into the global marketplace with us! 
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6 mb-15">
                            <a href="#">
                                <div className="banner-img wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    <img src="assets/imgs/banner/banner-2.png" alt="" />
                                    <div className="banner-text">
                                        <h4>
                                            International Supplier Branding
                                        </h4>
                                        <p>
                                            Build a brand that stands out on the global stage. Learn effective supplier branding strategies. 
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 d-md-none d-lg-flex mb-15">
                            <a href="#">
                            <div className="banner-img mb-sm-0 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                <img src="assets/imgs/banner/banner-3.png" alt="" />
                                <div className="banner-text">
                                    <h4>Logistics</h4>
                                    <p>
                                        Master the art of international logistics. Ensure smooth and efficient operations with our insights.
                                    </p>
                                </div>
                            </div>
                            </a>
                        </div>

                        <div className="col-lg-4 col-md-6">
                            <a href="#">
                                <div className="banner-img wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                    <img src="assets/imgs/banner/banner-4.png" alt="" />
                                    <div className="banner-text">
                                        <h4>
                                            Turnkey Projects
                                        </h4>
                                        <p>
                                            Transform your business with complete turnkey solutions. From start to finish, we've got you covered. 
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <a href="#">
                                <div className="banner-img wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                                    <img src="assets/imgs/banner/banner-5.png" alt="" />
                                    <div className="banner-text">
                                        <h4>
                                            Sourcing Solutions
                                        </h4>
                                        <p>
                                            Find the best suppliers and sourcing solutions for your needs. Connect with top suppliers worldwide.
                                        </p>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="col-lg-4 d-md-none d-lg-flex">
                            <a href="#">
                            <div className="banner-img mb-sm-0 wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                                <img src="assets/imgs/banner/banner-6.png" alt="" />
                                <div className="banner-text">
                                    <h4>My Price My Bid</h4>
                                    <p>
                                        Take control of your pricing and bids. Learn how to strategically set your prices and win bids globally.
                                    </p>
                                </div>
                            </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="popular-categories section-padding">
                <div className="container">
                    <div className="section-title">
                        <div className="title">
                            <h3>We cover a wide variety of Industries</h3>
                            <a className="show-all" href="#">
                                All Industries
                                <i className="fi-rs-angle-right"></i>
                            </a>
                        </div>
                        <div className="slider-arrow slider-arrow-2 flex-right carausel-8-columns-arrow" id="carausel-8-columns-arrows"></div>
                    </div>
                    <div className="carausel-8-columns-cover position-relative">
                        <div className="carausel-8-columns" id="carausel-8-columns">
                            <div className="card-1">
                                <figure className="img-hover-scale overflow-hidden">
                                    <a href="shop-grid-right.html"><img src="assets/imgs/theme/icons/agricultural.svg" alt="" /></a>
                                </figure>
                                <h6>
                                    <a href="shop-grid-right.html">Agricultural</a>
                                </h6>
                            </div>
                            <div className="card-1">
                                <figure className="img-hover-scale overflow-hidden">
                                    <a href="shop-grid-right.html"><img src="assets/imgs/theme/icons/chemicals.svg" alt="" /></a>
                                </figure>
                                <h6>
                                    <a href="shop-grid-right.html">Chemicals</a>
                                </h6>
                            </div>
                            <div className="card-1">
                                <figure className="img-hover-scale overflow-hidden">
                                    <a href="shop-grid-right.html"><img src="assets/imgs/theme/icons/electrical-equipment&supplies.svg" alt="" /></a>
                                </figure>
                                <h6>
                                    <a href="shop-grid-right.html">Electrical Equipment & Supplies</a>
                                </h6>
                            </div>
                            <div className="card-1">
                                <figure className="img-hover-scale overflow-hidden">
                                    <a href="shop-grid-right.html"><img src="assets/imgs/theme/icons/apparel.svg" alt="" /></a>
                                </figure>
                                <h6>
                                    <a href="shop-grid-right.html">Apparel</a>
                                </h6>
                            </div>
                            <div className="card-1">
                                <figure className="img-hover-scale overflow-hidden">
                                    <a href="shop-grid-right.html"><img src="assets/imgs/theme/icons/Constraction.svg" alt="" /></a>
                                </figure>
                                <h6>
                                    <a href="shop-grid-right.html">Constraction</a>
                                </h6>
                            </div>
                            <div className="card-1">
                                <figure className="img-hover-scale overflow-hidden">
                                    <a href="shop-grid-right.html"><img src="assets/imgs/theme/icons/energy.svg" alt="" /></a>
                                </figure>
                                <h6>
                                    <a href="shop-grid-right.html">Energy</a>
                                </h6>
                            </div>
                            <div className="card-1">
                                <figure className="img-hover-scale overflow-hidden">
                                    <a href="shop-grid-right.html"><img src="assets/imgs/theme/icons/beauty.svg" alt="" /></a>
                                </figure>
                                <h6>
                                    <a href="shop-grid-right.html">Beauty & Personal Care</a>
                                </h6>
                            </div>
                            <div className="card-1">
                                <figure className="img-hover-scale overflow-hidden">
                                    <a href="shop-grid-right.html"><img src="assets/imgs/theme/icons/consumer-electronics.svg" alt="" /></a>
                                </figure>
                                <h6>
                                    <a href="shop-grid-right.html">Consumer Electronics</a>
                                </h6>
                            </div>
                            <div className="card-1">
                                <figure className="img-hover-scale overflow-hidden">
                                    <a href="shop-grid-right.html"><img src="assets/imgs/theme/icons/fabrication.svg" alt="" /></a>
                                </figure>
                                <h6>
                                    <a href="shop-grid-right.html">Fabrication Services</a>
                                </h6>
                            </div>
                            <div className="card-1">
                                <figure className="img-hover-scale overflow-hidden">
                                    <a href="shop-grid-right.html"><img src="assets/imgs/theme/icons/home-appliance.svg" alt="" /></a>
                                </figure>
                                <h6><a href="shop-grid-right.html">Home Appliance</a></h6>
                            </div>
                            <div className="card-1">
                                <figure className="img-hover-scale overflow-hidden">
                                    <a href="shop-grid-right.html"><img src="assets/imgs/theme/icons/lights.svg" alt="" /></a>
                                </figure>
                                <h6><a href="shop-grid-right.html">Lights & Lighting</a></h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </>
  )
}

export default Home;
