/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <>
        <footer className="main">
            <section className="newsletter mb-15 wow animate__animated animate__fadeIn">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="position-relative newsletter-inner">
                                <div className="newsletter-content">
                                    <h2 className="mb-20">
                                        Stay home & get your daily <br />
                                        needs from our services
                                    </h2>
                                    {/* <!-- <p className="mb-45">Start You'r Daily Shopping with <span className="text-brand">Nest Mart</span></p> --> */}
                                    <form className="form-subcriber d-flex">
                                        <input type="email" placeholder="Your emaill address" />
                                        <button className="btn" type="submit">Subscribe</button>
                                    </form>
                                </div>
                                {/* <!-- <img src="assets/imgs/banner/banner-9.png" alt="newsletter" /> --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="section-padding footer-mid">
                <div className="container pt-15 pb-20 border-top">
                    <div className="row">
                        <div className="col">
                            <div className="widget-about font-md mb-md-3 mb-lg-3 mb-xl-0 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                                <div className="logo mb-30">
                                    <Link to="/" className="mb-15"><img src="assets/imgs/theme/logo-2.svg" alt="logo" /></Link>
                                    <p className="font-lg text-heading">Procure Log India is an online B2B market place where we connect importers around the globe </p>
                                </div>
                                <ul className="contact-infor">
                                    <li><img src="assets/imgs/theme/icons/icon-location.svg" alt="" /><strong>Address: </strong> <span>5171 W Campbell Ave undefined Kent, Utah 53127 United States</span></li>
                                    <li><img src="assets/imgs/theme/icons/icon-contact.svg" alt="" /><strong>Call Us:</strong><span>(+91) - 540-025-124553</span></li>
                                    <li><img src="assets/imgs/theme/icons/icon-email-2.svg" alt="" /><strong>Email:</strong><span>sale@Nest.com</span></li>
                                </ul>
                            </div>
                        </div>
                        <div className="footer-link-widget col wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
                            <h4 className="widget-title">Company</h4>
                            <ul className="footer-list mb-sm-5 mb-md-0">
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/">Why Us</Link></li>
                                <li><Link to="/">Vision</Link></li>
                                <li><Link to="/">How we work</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                                <li><Link to="/">Non-Register Exporter</Link></li>
                                <li><Link to="/">Buyer Consol Service</Link></li>
                                <li><Link to="/">Deadstock Clearance</Link></li>
                                <li><Link to="/">Trade Assurance</Link></li>
                            </ul>
                        </div>
                        <div className="footer-link-widget col wow animate__animated animate__fadeInUp" data-wow-delay=".2s">
                            <h4 className="widget-title">Financial Solution</h4>
                            <ul className="footer-list mb-sm-5 mb-md-0">
                                <li><Link to="/">Working capital</Link></li>
                                <li><Link to="/">Finance For Machinery</Link></li>
                                <li><Link to="/">Finance For Capital goods</Link></li>
                                <li><Link to="/">Import Now, Pay Later</Link></li>
                            </ul>
                        </div>
                        <div className="footer-link-widget col wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
                            <h4 className="widget-title">Procurement Solution</h4>
                            <ul className="footer-list mb-sm-5 mb-md-0">
                                <li><Link to="/">Information Gathering & Product sourcing</Link></li>
                                <li><Link to="/">Obtaining Competitive Quotation</Link></li>
                                <li><Link to="/">Conduct factory verification & supplier evaluation</Link></li>
                                <li><Link to="/">Sample Arrangement</Link></li>
                                <li><Link to="/">Quality Control and Inspection Report</Link></li>
                            </ul>
                        </div>
                        <div className="footer-link-widget col wow animate__animated animate__fadeInUp" data-wow-delay=".4s">
                            <h4 className="widget-title">Logistics Solution</h4>
                            <ul className="footer-list mb-sm-5 mb-md-0">
                                <li><Link to="/">Shipping coordination</Link></li>
                                <li><Link to="/">Custom Clearance Assistance</Link></li>
                                <li><Link to="/">Import Duties and Taxes Calculation</Link></li>
                                <li><Link to="/">Warehouse Solutions</Link></li>
                                <li><Link to="/">Inventory Management</Link></li>
                                <li><Link to="/">Supply Chain Optimization</Link></li>
                                <li><Link to="/">Customer Help</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div className="container pb-15 wow animate__animated animate__fadeInUp" data-wow-delay="0">
                <div className="row align-items-center">
                    <div className="col-12 mb-15">
                        <div className="footer-bottom"></div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6">
                        <p className="font-sm mb-0">&copy; 2024<br />All rights reserved</p>
                    </div>
                    <div className="col-xl-4 col-lg-6 text-center d-none d-xl-block">
                        <div className="hotline d-lg-inline-flex mr-30">
                            <img src="assets/imgs/theme/icons/phone-call.svg" alt="hotline" />
                            <p>1900 - 6666<span>Working 8:00 - 22:00</span></p>
                        </div>
                        <div className="hotline d-lg-inline-flex">
                            <img src="assets/imgs/theme/icons/phone-call.svg" alt="hotline" />
                            <p>1900 - 8888<span>24/7 Support Center</span></p>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-6 text-end d-none d-md-block">
                        <div className="mobile-social-icon">
                            <h6>Follow Us</h6>
                            <Link to="/"><img src="assets/imgs/theme/icons/icon-facebook-white.svg" alt="" /></Link>
                            <Link to="/"><img src="assets/imgs/theme/icons/icon-twitter-white.svg" alt="" /></Link>
                            <Link to="/"><img src="assets/imgs/theme/icons/icon-instagram-white.svg" alt="" /></Link>
                            <Link to="/"><img src="assets/imgs/theme/icons/icon-youtube-white.svg" alt="" /></Link>
                        </div>
                        <p className="font-sm">Subscribe to get daily update</p>
                    </div>
                </div>
            </div>
        </footer>

        {/* <div id="preloader-active">
            <div className="preloader d-flex align-items-center justify-content-center">
                <div className="preloader-inner position-relative">
                    <div className="text-center">
                        <img src="assets/imgs/theme/loading.gif" alt="" />
                    </div>
                </div>
            </div>
        </div> */}
    </>
  )
}

export default Footer
