import React from 'react'
import { Link } from 'react-router-dom';
                
function Navbar() {
  return (
    <>
        <header className="header-area header-style-1 header-height-2">
            <div className="mobile-promotion">
                <span>Grand opening, <strong>up to 15%</strong> off all items. Only <strong>3 days</strong> left</span>
            </div>

            <div className="header-bottom header-bottom-bg-color sticky-bar">
                <div className="container">
                    <div className="header-wrap header-space-between position-relative">
                        <div className="logo logo-width-1 d-block">
                            <Link to="/"><img src="assets/imgs/theme/logo-2.svg" alt="logo" /></Link>
                        </div>
                        <div className="header-nav d-none d-lg-flex">
                            <div className="main-categori-wrap d-none d-lg-block">
                                <Link to="/" className="categories-button-active">
                                    <span className="fi-rs-apps"></span> <span className="et">All</span>Sectors
                                    <i className="fi-rs-angle-down"></i>
                                </Link>
                                <div className="categories-dropdown-wrap categories-dropdown-active-large font-heading">
                                    <div className="d-flex categori-dropdown-inner">
                                        <ul>
                                            <li>
                                                <Link to="/"> <img src="assets/imgs/theme/icons/category-1.svg" alt="" />Milks and Dairies</Link>
                                            </li>
                                            <li>
                                                <Link to="/"> <img src="assets/imgs/theme/icons/category-2.svg" alt="" />Clothing & beauty</Link>
                                            </li>
                                            <li>
                                                <Link to="/"> <img src="assets/imgs/theme/icons/category-3.svg" alt="" />Pet Foods & Toy</Link>
                                            </li>
                                            <li>
                                                <Link to="/"> <img src="assets/imgs/theme/icons/category-4.svg" alt="" />Baking material</Link>
                                            </li>
                                            <li>
                                                <Link to="/"> <img src="assets/imgs/theme/icons/category-5.svg" alt="" />Fresh Fruit</Link>
                                            </li>
                                        </ul>
                                        <ul className="end">
                                            <li>
                                                <Link to="/"> <img src="assets/imgs/theme/icons/category-6.svg" alt="" />Wines & Drinks</Link>
                                            </li>
                                            <li>
                                                <Link to="/"> <img src="assets/imgs/theme/icons/category-7.svg" alt="" />Fresh Seafood</Link>
                                            </li>
                                            <li>
                                                <Link to="/"> <img src="assets/imgs/theme/icons/category-8.svg" alt="" />Fast food</Link>
                                            </li>
                                            <li>
                                                <Link to="/"> <img src="assets/imgs/theme/icons/category-9.svg" alt="" />Vegetables</Link>
                                            </li>
                                            <li>
                                                <Link to="/"> <img src="assets/imgs/theme/icons/category-10.svg" alt="" />Bread and Juice</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="more_slide_open" style={{display: 'none'}}>
                                        <div className="d-flex categori-dropdown-inner">
                                            <ul>
                                                <li>
                                                    <Link to="/"> <img src="assets/imgs/theme/icons/icon-1.svg" alt="" />Milks and Dairies</Link>
                                                </li>
                                                <li>
                                                    <Link to="/"> <img src="assets/imgs/theme/icons/icon-2.svg" alt="" />Clothing & beauty</Link>
                                                </li>
                                            </ul>
                                            <ul className="end">
                                                <li>
                                                    <Link to="/"> <img src="assets/imgs/theme/icons/icon-3.svg" alt="" />Wines & Drinks</Link>
                                                </li>
                                                <li>
                                                    <Link to="/"> <img src="assets/imgs/theme/icons/icon-4.svg" alt="" />Fresh Seafood</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="more_categories"><span className="icon"></span> <span className="heading-sm-1">Show more...</span></div>
                                </div>
                            </div>
                            <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading">
                                <nav>
                                    <ul>
                                        <li className="position-static">
                                            <Link to="/">Our Solutions <i className="fi-rs-angle-down"></i></Link>
                                            <ul className="mega-menu">
                                                <li className="sub-mega-menu sub-mega-menu-width-22">
                                                    <Link to="/" className="menu-title" >B2B Market</Link>
                                                    <p>Marketplace, where you can find thousands of products from Suppliers worldwide</p>
                                                </li>
                                                <li className="sub-mega-menu sub-mega-menu-width-22">
                                                    <Link to="/" className="menu-title" >Unclaimed Cargo</Link>
                                                    <p>Sell and buy cargo that has been rejected by the initial buyer. Only goods in good condition.</p>
                                                </li>
                                                <li className="sub-mega-menu sub-mega-menu-width-22">
                                                    <Link to="/" className="menu-title" >Quick Logistics</Link>
                                                    <p>Get an immediate calculation for your logistics in almost any direction.</p>
                                                </li>
                                                <li className="sub-mega-menu sub-mega-menu-width-34">
                                                    <div className="menu-banner-wrap">
                                                        <Link to="/" href="shop-product-right.html"><img src="assets/imgs/banner/banner-menu.png" alt="Nest" /></Link>
                                                        <div className="menu-banner-content">
                                                            <h4>Hot deals</h4>
                                                            <h3>
                                                                Don't miss<br />
                                                                Trending
                                                            </h3>
                                                            <div className="menu-banner-price">
                                                                <span className="new-price text-success">Save to 50%</span>
                                                            </div>
                                                            <div className="menu-banner-btn">
                                                                <Link to="/" href="shop-product-right.html">Shop now</Link>
                                                            </div>
                                                        </div>
                                                        <div className="menu-banner-discount">
                                                            <h3>
                                                                <span>25%</span>
                                                                off
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li className="position-static">
                                            <Link to="/" >Resources <i className="fi-rs-angle-down"></i></Link>
                                            <ul className="mega-menu">
                                                <li className="sub-mega-menu sub-mega-menu-width-22">
                                                    <Link to="/" className="menu-title" >Fruit & Vegetables</Link>
                                                    <ul>
                                                        <li><Link to="/" href="shop-product-right.html">Meat & Poultry</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Fresh Vegetables</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Herbs & Seasonings</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Cuts & Sprouts</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Exotic Fruits & Veggies</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Packaged Produce</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="sub-mega-menu sub-mega-menu-width-22">
                                                    <Link to="/" className="menu-title" >Breakfast & Dairy</Link>
                                                    <ul>
                                                        <li><Link to="/" href="shop-product-right.html">Milk & Flavoured Milk</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Butter and Margarine</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Eggs Substitutes</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Marmalades</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Sour Cream</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Cheese</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="sub-mega-menu sub-mega-menu-width-22">
                                                    <Link to="/" className="menu-title" >Meat & Seafood</Link>
                                                    <ul>
                                                        <li><Link to="/" href="shop-product-right.html">Breakfast Sausage</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Dinner Sausage</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Chicken</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Sliced Deli Meat</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Wild Caught Fillets</Link></li>
                                                        <li><Link to="/" href="shop-product-right.html">Crab and Shellfish</Link></li>
                                                    </ul>
                                                </li>
                                                <li className="sub-mega-menu sub-mega-menu-width-34">
                                                    <div className="menu-banner-wrap">
                                                        <Link to="/" href="shop-product-right.html"><img src="assets/imgs/banner/banner-menu.png" alt="Nest" /></Link>
                                                        <div className="menu-banner-content">
                                                            <h4>Hot deals</h4>
                                                            <h3>
                                                                Don't miss<br />
                                                                Trending
                                                            </h3>
                                                            <div className="menu-banner-price">
                                                                <span className="new-price text-success">Save to 50%</span>
                                                            </div>
                                                            <div className="menu-banner-btn">
                                                                <Link to="/" href="shop-product-right.html">Shop now</Link>
                                                            </div>
                                                        </div>
                                                        <div className="menu-banner-discount">
                                                            <h3>
                                                                <span>25%</span>
                                                                off
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className="hotline d-none d-lg-flex">
                            <div className="search-style-2">
                                <form action="#">
                                    <select className="select-active">
                                        <option>All Categories</option>
                                        <option>Milks and Dairies</option>
                                        <option>Wines & Alcohol</option>
                                        <option>Clothing & Beauty</option>
                                        <option>Pet Foods & Toy</option>
                                        <option>Fast food</option>
                                        <option>Baking material</option>
                                        <option>Vegetables</option>
                                        <option>Fresh Seafood</option>
                                        <option>Noodles & Rice</option>
                                        <option>Ice cream</option>
                                    </select>
                                    <input type="text" placeholder="Search for items..." />
                                </form>
                            </div>
                            <div className="header-action-icon-2">
                                <ul>
                                    <li>
                                        <Link to="/" href="shop-grid-right.html">Sign In</Link>
                                    </li>
                                    <li className="main-categori-wrap">
                                        <Link to="/" href="shop-grid-right.html" className="categories-button-active">Sign Up</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="header-action-icon-2 d-block d-lg-none">
                            <div className="burger-icon burger-icon-white">
                                <span className="burger-icon-top"></span>
                                <span className="burger-icon-mid"></span>
                                <span className="burger-icon-bottom"></span>
                            </div>
                        </div>
                        <div className="header-action-right d-block d-lg-none">
                            <div className="header-action-2">
                                <div className="header-action-icon-2">
                                    <Link to="/" href="shop-wishlist.html">
                                        <img alt="Nest" src="assets/imgs/theme/icons/icon-heart.svg" />
                                        <span className="pro-count white">4</span>
                                    </Link>
                                </div>
                                <div className="header-action-icon-2">
                                    <Link to="/" className="mini-cart-icon" >
                                        <img alt="Nest" src="assets/imgs/theme/icons/icon-cart.svg" />
                                        <span className="pro-count white">2</span>
                                    </Link>
                                    <div className="cart-dropdown-wrap cart-dropdown-hm2">
                                        <ul>
                                            <li>
                                                <div className="shopping-cart-img">
                                                    <Link to="/" href="shop-product-right.html"><img alt="Nest" src="assets/imgs/shop/thumbnail-3.jpg" /></Link>
                                                </div>
                                                <div className="shopping-cart-title">
                                                    <h4><Link to="/" href="shop-product-right.html">Plain Striola Shirts</Link></h4>
                                                    <h3><span>1 × </span>$800.00</h3>
                                                </div>
                                                <div className="shopping-cart-delete">
                                                    <Link to="/" ><i className="fi-rs-cross-small"></i></Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="shopping-cart-img">
                                                    <Link to="/" href="shop-product-right.html"><img alt="Nest" src="assets/imgs/shop/thumbnail-4.jpg" /></Link>
                                                </div>
                                                <div className="shopping-cart-title">
                                                    <h4><Link to="/" href="shop-product-right.html">Macbook Pro 2022</Link></h4>
                                                    <h3><span>1 × </span>$3500.00</h3>
                                                </div>
                                                <div className="shopping-cart-delete">
                                                    <Link to="/" ><i className="fi-rs-cross-small"></i></Link>
                                                </div>
                                            </li>
                                        </ul>
                                        <div className="shopping-cart-footer">
                                            <div className="shopping-cart-total">
                                                <h4>Total <span>$383.00</span></h4>
                                            </div>
                                            <div className="shopping-cart-button">
                                                <Link to="/" href="shop-cart.html">View cart</Link>
                                                <Link to="/" href="shop-checkout.html">Checkout</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>
  )
}

export default Navbar
